import { DoBootstrap, Injector, NgModule } from '@angular/core';

import { StarsElement } from './stars.component';
import { createCustomElement } from '@angular/elements';
import { StarModule } from '@bazis/shared/components/star-rating/star/star.module';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [StarsElement],
    imports: [CommonModule, StarModule],
    exports: [StarsElement],
})
export class StarsModule implements DoBootstrap {
    constructor(private injector: Injector) {}

    ngDoBootstrap(): void {
        const el = createCustomElement(StarsElement, {
            injector: this.injector,
        });

        customElements.define('bazis-stars', el);
    }
}
